import React from 'react';
import { SEO } from '../components/SEO';
import styled from '../styled';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { CookieBar } from '../components/CookieBar';
import withRoot from '../withRoot';
import {
  BlockContent,
  sanitySerializers
} from '../components/SanityBlockContent';
import { graphql, Link } from 'gatsby';
import { Author } from '../types/Author';
import { SanityPost } from '../types/Article';
import { Typography, IconButton, Button } from '@material-ui/core';
import { SanityPostLink } from '../components/PostLink';
import { AffiliateProgramList } from '../types/AffiliateProgramList';
import { Linkedin, Twitter, Globe, Instagram, ArrowLeft } from 'react-feather';

type IContext = {
  sanityAuthor: Author;
  allSanityAffiliateNiche: {
    edges: {
      node: AffiliateProgramList;
    }[];
  };
  allSanityPost: {
    edges: {
      node: SanityPost;
    }[];
  };
};

const AuthorImage = styled('img')`
  width: 200px;
  border-radius: 100%;
  border: 12px solid white;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const AuthorWrapper = styled('div')`
  max-width: 300px;
  margin: 150px auto ${(p) => p.theme.spacing(6)}px;
  text-align: center;

  p a {
    border-bottom: 1px solid;
  }
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(8)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;
  margin-bottom: ${(p) => p.theme.spacing(8)}px;

  & > div {
    margin-bottom: 12px;
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const PostsWrapper = styled('div')`
  margin: 24px auto;
  max-width: 1200px;

  @media (max-width: 698px) {
    margin: 24px;
  }

  p,
  ul,
  li {
    font-size: 18px;
    line-height: 30px;

    a {
      color: ${(p) => p.theme.palette.primary.main};
      border-bottom: 1px solid;
    }
  }
`;

export const pageQuery = graphql`
  query($authorId: String) {
    sanityAuthor(_id: { eq: $authorId }) {
      _id
      slug {
        current
      }
      name
      jobTitle
      twitter
      website
      instagram
      linkedin
      bio {
        children {
          text
        }
      }
      _rawBio
      image {
        image {
          src
          width
          height
        }
        alt
      }
    }
    allSanityAffiliateNiche(
      filter: { author: { _id: { eq: $authorId } } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          title
          description
          slug {
            current
          }
          mainImage {
            alt
            image {
              src
              width
              height
            }
          }
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allSanityPost(
      filter: { author: { _id: { eq: $authorId } } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          publishedAt(formatString: "MMMM DD, YYYY")
          lastUpdated(formatString: "MMMM DD, YYYY")
          featured
          categories {
            slug {
              current
            }
          }
          mainImage {
            image {
              src
            }
          }
          title
          description
          slug {
            current
          }
        }
      }
    }
  }
`;

const Template = ({ data }: { data: IContext }) => {
  const {
    sanityAuthor: author,
    allSanityPost: sanityPosts,
    allSanityAffiliateNiche
  } = data;

  const posts = sanityPosts.edges
    .filter((e) => !!e.node.publishedAt)
    .map((e) => e.node);

  const sanityAffiliateNiches = allSanityAffiliateNiche.edges
    .filter((e) => !!e.node.publishedAt)
    .map((e) => e.node);

  const description = author.bio
    .map((b) => b.children.map((c) => c.text).join(''))
    .join('');

  return (
    <>
      <>
        <Navigation />
        <SEO
          title={`${author.name}, Author at Affilimate's Blog`}
          pathname={`/blog/authors/${author.slug.current}/`}
          description={description}
        />
        <AuthorWrapper>
          <AuthorImage
            src={author.image.image.src}
            alt={author.name}
            loading="lazy"
            width="200"
            height="200"
          />
          <Typography
            variant="h4"
            component="h1"
            style={{ fontWeight: 'bold' }}
          >
            {author.name}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            paragraph
          >
            {author.jobTitle}
          </Typography>
          <BlockContent
            blocks={author._rawBio}
            serializers={sanitySerializers}
          />
          <div>
            {author.website && (
              <IconButton href={author.website} target="_blank">
                <Globe size={18} />
              </IconButton>
            )}
            {author.twitter && (
              <IconButton
                href={`https://twitter.com/${author.twitter}`}
                target="_blank"
              >
                <Twitter size={18} />
              </IconButton>
            )}
            {author.instagram && (
              <IconButton
                href={`https://instagram.com/${author.instagram}`}
                target="_blank"
              >
                <Instagram size={18} />
              </IconButton>
            )}
            {author.linkedin && (
              <IconButton href={author.linkedin} target="_blank">
                <Linkedin size={18} />
              </IconButton>
            )}
          </div>
          <Link to="/blog/">
            <Button
              variant="contained"
              color="default"
              style={{ marginTop: '24px' }}
            >
              <ArrowLeft size={18} /> &nbsp; Back to the blog
            </Button>
          </Link>
        </AuthorWrapper>
        <PostsWrapper>
          <Typography
            variant="body1"
            component="h2"
            paragraph
            color="textSecondary"
            style={{ textAlign: 'center', marginBottom: '24px' }}
          >
            Articles by {author.name} on Affilimate.com:
          </Typography>
          <Grid>
            {posts.map((post) => (
              <SanityPostLink key={post.slug.current} post={post} />
            ))}
            {sanityAffiliateNiches.map((niche) => (
              <SanityPostLink post={niche} key={niche.slug.current} />
            ))}
          </Grid>
        </PostsWrapper>
        <Footer />
        <CookieBar />
      </>
    </>
  );
};

export default withRoot(Template);
